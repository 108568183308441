<template>
  <v-main>
    <CustomLayout>
      <template v-slot:side>
        <v-list-item class="py-2">
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              <div>E-Mail Vorlagen</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>
          <!-- add -->
          <v-list-item link v-on:click="addNewEmailTemplate">
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Neue E-Mail Vorlage</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- list items -->
          <v-list-item-group v-model="selectedTemplateIndex" color="blue">
            <v-list-item
                v-for="template in emailTemplates"
                :key="template.id"
                v-on:click="selectEmailTemplate(template)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ template.name === "" ? "unbenannte Vorlage" : template.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </template>

      <!-- template area -->
      <div>
        <div v-for="(template, index) in emailTemplates" :key="template.id" v-if="selectedTemplateIndex === index"
             class="px-4">

          <div class="d-flex align-center">

            <v-row no-gutters>
              <v-col cols="6">
                <!-- show template name -->
                <div v-if="!editingTemplateName" class="d-flex align-center flex-grow-1" style="height: 72px">
                  <div class="grey--text mr-2 mt-1">Name:</div>
                  <div class="text-h5">{{ template.name === "" ? "unbenannte Vorlage" : template.name }}</div>
                  <v-btn class="ml-3" fab x-small color="blue" outlined :ripple="false" v-on:click="editTemplateName">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>

                <!-- edit template name -->
                <div v-if="editingTemplateName" class="d-flex align-center flex-grow-1" style="height: 72px">
                  <v-btn v-if="false" class="mr-3" fab small color="success" depressed outlined
                         v-on:click="stopTemplateNameEditing">
                    <v-icon> mdi-check</v-icon>
                  </v-btn>
                  <v-text-field ref="tfTemplateName" class="text-h5" color="blue" v-model="template.name" outlined
                                hide-details="auto" @blur="stopTemplateNameEditing"
                                @input="templateContentChanged(template)"></v-text-field>
                </div>
              </v-col>
              <v-col cols="6">
                <!-- show subject -->
                <div v-if="!editingTemplateSubject" class="d-flex align-center flex-grow-1" style="height: 72px">
                  <div class="grey--text mr-2 mt-1">Betreff:</div>
                  <div class="text-h5">{{ template.subject === "" ? "Bitte Betreff festlegen" : template.subject }}
                  </div>
                  <v-btn class="ml-3" fab x-small color="blue" outlined :ripple="false"
                         v-on:click="editTemplateSubject">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>

                <!-- edit template name -->
                <div v-if="editingTemplateSubject" class="d-flex align-center flex-grow-1" style="height: 72px">
                  <v-btn v-if="false" class="mr-3" fab small color="success" depressed outlined
                         v-on:click="stopTemplateNameEditing">
                    <v-icon> mdi-check</v-icon>
                  </v-btn>
                  <v-text-field ref="tfTemplateSubject" class="text-h5" color="blue" v-model="template.subject" outlined
                                hide-details="auto" @blur="stopTemplateNameEditing"
                                @input="templateContentChanged(template)"></v-text-field>
                </div>
              </v-col>
            </v-row>

            <div class="d-flex align-center ml-3">
              <v-btn color="black" outlined @click="optionsDialogShown = true">
                Optionen
                <v-icon right>mdi-dots-vertical</v-icon>
              </v-btn>
              <v-btn color="green darken-1" outlined class="ml-3" @click="sendOverlayShown = true" disabled>
                Versenden
                <v-icon right>mdi-send</v-icon>
              </v-btn>
            </div>
          </div>

          <!-- options dialog -->
          <v-dialog v-model="optionsDialogShown" width="500px">
            <v-card>
              <v-card-title>
                <div>Optionen</div>
              </v-card-title>
              <v-card-text>
                <v-text-field label="Absender-Adresse" outlined class="mt-2 mb-2" hide-details="auto"
                              v-model="template['preferredSenderAddress']" :disabled="template['locked']"
                              @input="templateContentChanged(template)">
                </v-text-field>
                <v-checkbox
                    v-model="template['locked']" class="mt-0" disabled
                    hide-details label="Template sperren"
                    @change="templateContentChanged(template)"
                ></v-checkbox>
                <v-checkbox
                    v-model="template['allowRepeatedSending']" class="mt-1"
                    hide-details label="Mehrfaches Senden erlauben" :disabled="template['locked']"
                    @change="templateContentChanged(template)"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- send overlay -->
          <v-overlay :value="sendOverlayShown">
            <v-card light v-click-outside="closeOverlays">
              <v-card-title>E-Mail versenden</v-card-title>
              <v-card-text>
                <div class="d-flex align-baseline">
                  <div class="mr-2">Absender:</div>
                  <div class="text-body-1 black--text">info@roy-spitznder.de</div>
                </div>
                <div class="d-flex align-baseline">
                  <div class="mr-2">Empfänger:</div>
                  <v-text-field v-model="recipientAddress"></v-text-field>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" dark text @click="sendMail(template)">
                  Senden
                  <v-icon right>mdi-send</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-overlay>
          <!-- send overlay end -->

          <div class="">
            <v-sheet elevation="2">
              <quill-editor
                  class="auto-height"
                  ref="myQuillEditor"
                  v-model="template.content"
                  :options="editorOption"
                  @change="templateContentChanged(template)"
              />
            </v-sheet>
          </div>

        </div>
      </div>

    </CustomLayout>

  </v-main>
</template>

<script>

import CustomLayout from "@/components/reusable/CustomLayout";
import _ from "lodash";

export default {
  name: "EmailTemplateEditor",
  components: { CustomLayout },
  data() {
    return {
      emailTemplates: null,
      selectedTemplateIndex: undefined,
      editingTemplateName: false,
      editingTemplateSubject: false,
      updatesToPush: [],
      sendOverlayShown: false,
      optionsDialogShown: false,
      recipientAddress: "",
      // static final fields
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{ "list": "ordered" }, { "list": "bullet" }],
            [{ "script": "sub" }, { "script": "super" }],
            [{ "header": [1, 2, 3, false] }],
            [{ "size": ["small", false, "large", "huge"] }],
            [{ "color": [] }, { "background": [] }],
            ["clean"]
          ]
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch("getRequest", ["email-templates", this.fetchTemplatesCallback]);
  },
  methods: {
    fetchTemplatesCallback(data) {
      this.emailTemplates = data;
    },
    addNewEmailTemplate() {
      this.stopTemplateNameEditing();
      this.$store.dispatch("postRequest", ["email-templates", "", this.templateAddedCallback]);
    },
    templateAddedCallback(newTemplate) {
      let updatedTemplateList = this.emailTemplates;
      updatedTemplateList.unshift(newTemplate); // add new template to the beginning of the list
      this.emailTemplates = [];
      this.$nextTick(() => { // wait a tick to make the make the ui realize that the list is empty
        this.emailTemplates = updatedTemplateList;
      });
      setTimeout(function() {
        this.selectedTemplateIndex = 0;
      }.bind(this), 200);
    },
    deleteEmailTemplate(template) {
      this.stopTemplateNameEditing();
      this.$store.dispatch("deleteRequest", ["email-templates/" + template.id]);
      let index = this.emailTemplates.indexOf(template);
      this.emailTemplates.splice(index, 1);
    },
    templateContentChanged(template) {
      let index = this.updatesToPush.indexOf(template);
      if (index === -1) {
        this.updatesToPush.push(template);
      }
      this.schedulePush();
    },
    schedulePush: _.debounce(function() {
      this.$store.dispatch("putRequest", ["email-templates", this.updatesToPush]);
      this.updatesToPush = [];
    }, 500),
    selectEmailTemplate() {
      this.stopTemplateNameEditing();
    },
    editTemplateName() {
      this.editingTemplateName = true;
      setTimeout(function() {
        this.$refs["tfTemplateName"][0].focus();
      }.bind(this), 100);
    },
    editTemplateSubject() {
      this.editingTemplateSubject = true;
      setTimeout(function() {
        this.$refs["tfTemplateSubject"][0].focus();
      }.bind(this), 100);
    },
    stopTemplateNameEditing() {
      this.editingTemplateName = false;
      this.editingTemplateSubject = false;
    },
    closeOverlays() {
      this.sendOverlayShown = false;
    },
    sendMail(template) {
      let data = {
        recipientAddress: this.recipientAddress
      };
      this.$store.dispatch("postRequest", ["mail-sender/template-to-mail-address/" + template.id, data]);
      this.recipientAddress = "";
      this.closeOverlays();
    }
  }
};
</script>

<style>
.quill-editor.auto-height {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px - 72px - 16px);
}

.quill-editor.auto-height .ql-container {
  overflow: auto;
}
</style>

<style scoped>
.v-btn:focus::before {
  opacity: 0;
}

</style>